<div class="row" style="margin-bottom: 100px;">
    <div *ngFor="let pedido of pedidos" style="padding-bottom: 10px;" class="col-md-4 col-sm-12  col-lg-3 col-xs-6 border-card" (click)="redirecionar(pedido)">
        <div class="row" style="margin-bottom:5%;">
            <div class="col-md-4 col-sm-2 col-xs-2">
                <img *ngIf="pedido.avatar" class="padrao_img" [src]="pedido.avatar" />
                <img  *ngIf="!pedido.avatar" class="padrao_img"  src="../assets/user.png" />
            </div>
            <div class="col-md-8 col-sm-4 col-xs-4" style="margin-top:5%;">
                <h6><b>{{pedido.nome_fantasia}}</b></h6>
                 <p>{{pedido.celular}}</p>
            </div>
        </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <b>Solicitação:</b> {{pedido.data_solicitacao}} <br>
                    <b>Finalização:</b> {{pedido.data_finalizacao}}
                </div>
            </div>

            <div class="row">
              
                <div class="col-md-12 col-sm-12 col-xs-12" *ngFor="let rota of pedido.todas_rotas; let i = index">
                    <br>
                    <b>Endereço {{ i + 1 }}:</b> {{rota.endereco}}, {{rota.numero}}
                    {{rota.complemento}}
                   {{rota.bairro}} - {{rota.cidade}} - {{rota.estado}}
                </div> 

                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" *ngIf="tipo == 'entregador'">
                    <b>Quilometragem:</b> {{pedido.total_km}} Km
                </div> 

                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" *ngIf="tipo == 'cliente'">
                    <b>Quilometragem:</b> {{pedido.total_km_cliente}} Km
                </div> 
            </div>
        <br>
            <div class="row">
                <div class="col-md-7 col-sm-7 col-xs-7">
                    <b>Status:</b><br>
                    <img class="bolinha_status"  *ngIf="pedido.nome_status == 'Cancelado'" src="assets/oval_off.png"/>   
                    <img   *ngIf="pedido.nome_status == 'Concluído'" class="bolinha_status" src="assets/Oval.png"/>  {{pedido.nome_status}}
                </div>
                <div class="col-md-5 col-sm-5 col-xs-5">
                    <b>Valor:</b><br>
                    {{pedido.valor_total}}
                </div>
            </div>
         
    </div>
</div>



<div *ngIf="pedidos == false" class="container ">
    <span class='nenhum_pedido'>Nenhum histórico de pedido no momento.  </span>
</div>
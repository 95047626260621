import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  constructor() { }

  removerDuplicidade(pedidos: any[]): any[] {
    const pedidosUnicos = [];
    const ids = new Set();

    pedidos.forEach(pedido => {
      if (!ids.has(pedido.id)) {
        ids.add(pedido.id);
        pedidosUnicos.push(pedido);
      }
    });

    return pedidosUnicos;
  }
}
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-historico-card',
  templateUrl: './historico-card.component.html',
  styleUrls: ['./historico-card.component.css']
})
export class HistoricoCardComponent implements OnInit {
  @Input() pedidos:any = [];
  @Input() tipo:any = "entregador";
  
  constructor(
    private router: Router,

  ) { }

  ngOnInit(): void {
  }
  redirecionar(pedido) {  
    
     this.router.navigate(["/ver-solicitacao", pedido]);
    

  } 
}

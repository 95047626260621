<div class="modal-body p-0 width-100 flex">
    <div class="img-modal"></div>
    <div class="p-4 width-100 flex">
        <p>
            <b *ngIf="valorZero">
                Você não possui valores definidos para as taxas. <br>
                Entre em contato com a administração
            </b>
            <b *ngIf="!valorZero">
                Esta será a taxa total do serviço: <br />
                {{valor_total}}
            </b><br />
        </p>
        <app-botao-vermelho *ngIf="!valorZero && valor_total != 'Calculando...'" (click)="prosseguirCancelar(true)" title="Prosseguir"
            class="p-4"></app-botao-vermelho>
        <app-botao-vermelho (click)="prosseguirCancelar(false)" title="Cancelar" class="p-4"></app-botao-vermelho>
    </div>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ConfirmacaoPedidoComponent } from '../confirmacao-pedido/confirmacao-pedido.component';

@Component({
  selector: 'app-exibir-valor-cliente',
  templateUrl: './exibir-valor-cliente.component.html',
  styleUrls: ['./exibir-valor-cliente.component.css']
})
export class ExibirValorClienteComponent implements OnInit {
  @Input() envio: boolean = false;

  public cliente: any = {};
  public param: any = {};

  public valorZero: boolean = false;

  public valor_total: any = 'Calculando...';
  public valor_km: string = '0';

  constructor(
    private _http: RequisicoesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private httpClient: HttpClient,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.param = JSON.parse(localStorage.getItem('shj.pedido_tipo_veiculo'));
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario'));

    this.calculaCorrida();
  }

  calculaCorrida() {
    let endereco = JSON.parse(localStorage.getItem('shj.pedido_enderecos'));

    let dados = {
      cliente_id: this.cliente.id,
      rotas: endereco,
      tipo_veiculo: this.param
    }

    if (endereco.length >= 1) {
      this._http.post("calculaDistancia", dados).subscribe(
        async (resposta: any) => {
          if (resposta.status == "sucesso") {
            localStorage.setItem('shj.pedido_valor_total', JSON.stringify(resposta.data.valor_corrida));
            localStorage.setItem('shj.pedido_total_km', JSON.stringify(resposta.data.total_km));
            this.formatCurrency(resposta.data.valor_corrida);
          } else {
            this.valorZero = true;
          }
        },
      );
    }
  }

  private formatCurrency(value: any): void {
    if (typeof value !== 'number') {
      value = parseFloat(value);
    }
    this.valor_total = 'R$ ' + value.toFixed(2).replace('.', ',');
  }

  prosseguirCancelar(bool) {
    if (bool == true) {
      this.modalService.dismissAll();
      this.modalService.open(ConfirmacaoPedidoComponent)
    } else {
      this.modalService.dismissAll();
    }
  }

  // prosseguirCancelar(bool){
  //   if(bool == true){
  //     this.modalService.dismissAll();
  //     let modal = this.modalService.open(ConfirmacaoPedidoComponent, {
  //       size: 'md '
  //     });
  //   }else{
  //     this.modalService.dismissAll();
  //   }   
  // }

}
